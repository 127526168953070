<template>
  <div class="areaList backMenu">
    <div class="">
      <el-card class="box-card">
        <!-- 搜索新建 -->
        <div class="MenuTop">
          <div>
            <el-input v-model="MenuApi.name" placeholder="请输入区域名称">
              <!-- <template slot="prepend">区域名称：</template> -->
            </el-input>
          </div>
          <div class="input-1">
            <el-input v-model="MenuApi.parent" placeholder="请输入上级名称">
              <!-- <template slot="prepend">上级名称：</template> -->
            </el-input>
          </div>
          <div class="btn">
            <el-button type="primary" @click="search">查 询</el-button>
          </div>
          <!-- v-if="BtnJurisdiction('/Auth/addMenu')" -->
          <div class="btn">
            <!-- <el-button type="primary" @click="addMenuDigo">新建地区</el-button> -->
          </div>
        </div>
        <!-- 菜单列表 -->
        <div class="MenuList scorr-roll">
          <el-table :data="MenuList.data" border style="width: 100%">
            <el-table-column
              prop="date"
              label="编号"
              min-width="50"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              min-width="140"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.name || "/" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="上级"
              min-width="140"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.parent_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="排序(越大越靠前)"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.sort }}
                </div>
              </template>
            </el-table-column>
            <!-- v-if="
                BtnJurisdiction('/Auth/handleMenu') ||
                BtnJurisdiction('/Auth/bottonList')
              " -->
            <el-table-column
              prop="address"
              label="操作"
              width="200"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="rootBtn">
                  <!-- v-if="BtnJurisdiction('/Auth/handleMenu')" -->
                  <button
                    @click="EditBtn(row)"
                    v-if="allJurisdiction.areaList74"
                  >
                    编辑
                  </button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="MenuList.per_page"
            layout="total, prev, pager, next, jumper"
            :total="MenuList.total"
            :current-page="MenuApi.page"
          >
          </el-pagination>
        </div>
        <!-- 新增和编辑弹框 -->
        <div>
          <el-dialog title="菜单" :visible.sync="isAdd" width="700px">
            <div class="AddMenu scorr-roll">
              <div class="AddMenu-1-box">
                <div>
                  <el-form label-width="120px">
                    <el-form-item label="名称:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.name"
                          placeholder="请输入菜单名称"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="上级:" v-if="addData.id == undefined">
                      <div class="inputbox">
                        <el-cascader
                          v-model="AreaArr"
                          :options="AreaList"
                          :props="propsValue"
                          clearable
                        ></el-cascader>
                      </div>
                    </el-form-item>
                    <el-form-item label="上级:" v-else>
                      <div class="inputbox">
                        <el-input
                          :disabled="true"
                          v-model.trim="addData.parent_name"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="排序:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.sort"
                          placeholder="请输入排序值"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isAdd = false">取 消</el-button>
              <el-button type="primary" @click="AddMenu">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
const { mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      MenuList: [], //菜单列表数据
      MenuApi: {
        parent: "",
        name: "",
        page: 1,
      }, //请求参数
      isAdd: false,
      addData: {
        parent_id: [], //父级id
        name: "", //名称
        sort: "", //类型 0菜单 1按钮
      },
      stairOptions: [],
      AreaArr: "",
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项
    };
  },
  computed: {
    ...commonIndex.mapState(["AreaList", "allJurisdiction", "detailsSeach"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.MenuApi = this.detailsSeach[router];
    }
    this.RootMenuList();
  },
  methods: {
    ...mapActions(["getdistrictList", "postdistrictadd", "postdistrictedit"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 打开编辑弹框
    EditBtn(data) {
      this.isAdd = true;
      //console.log(data);
      this.addData = { ...data };
    },
    // 新建菜单弹框
    addMenuDigo() {
      this.addData = {};
      this.isAdd = true;
    },
    // 新建地区
    async AddMenu() {
      //console.log(this.addData);
      if (!this.addData.sort || !this.addData.name) {
        this.$message({
          message: "请将带星号的填写完成",
          type: "warning",
        });
        return;
      }
      //console.log(this.addData.id);
      let data = {};
      if (this.addData.id) {
        this.addData.parent_id = this.AreaArr[this.AreaArr.length - 1];
        data = await this.postdistrictedit(this.addData);
      } else {
        data = await this.postdistrictadd(this.addData);
      }
      if (data.code == 200) {
        this.RootMenuList();
        this.isAdd = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 拿取权限列表里的菜单列表
    async RootMenuList() {
      let data = await this.getdistrictList(this.MenuApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.MenuApi };
      save.router = router;
      this.changeSeach(save);
      if (data.code == 200) {
        this.MenuList = data.data;
      }
    },
    // 前往按钮档期啊页面权限按钮页面
    toBtn(data) {
      this.$router.push({ path: "/RedactBtn", query: { id: data.id } });
      //
    },
    // 搜索菜单
    search() {
      this.MenuApi.page = 1;
      this.RootMenuList();
    },
    // 翻页
    handleCurrentChange(e) {
      this.MenuApi.page = e;
      this.RootMenuList();
    },
    // 按钮权限
    BtnJurisdiction,
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  // .box-card {
  //   margin-top: 10px;
  // }
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    .input-1 {
      margin-left: 5px;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06b7ae;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
      }
    }
  }
}
</style>
